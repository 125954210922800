import { useSelector } from 'react-redux';

import './t1.styl';

function T1(props) {
  const showData = useSelector(state => state.t1.showData);

  const {
    Navbar, InteractiveMap, Datapanel, Footer, 
  } = props;

  if (!Navbar) {
    throw new Error('Navbar required')
  }

  if (!InteractiveMap) {
    throw new Error('InteractiveMap required')
  }

  if (!Datapanel) {
    throw new Error('Datapanel required')
  }

  if (!Footer) {
    throw new Error('Footer required')
  }

  return (
    <div className="t1">
      <div className="t1__navbar">
        {Navbar}
      </div>
      <div className="t1__interactive-map">
        {InteractiveMap}
      </div>
      <div className={`t1__datapanel ${showData ? 't1__datapanel--active' : ''}`}>
        {Datapanel}
      </div>
      <div className="t1__footer">
        {Footer}
      </div>
    </div>
  )
}

export default T1;