const defaultState = {
  legendSelection: 'classes',
}

const legend = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_LEGEND_SWITCH_SELECTION':
      return {
        ...state,
        legendSelection: action.payload,
      }
    default:
      return state
  }
}

export default legend;