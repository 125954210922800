const expireDate = new Date();
expireDate.setMonth(expireDate.getMonth() + 6);

const cookies = [
  {
    name: 'cookie-agreed-categories',
    value: undefined, // set when user agrees to cookies
    expires: expireDate,
    category: 'functional',
    signed: true,
  },
]

export default cookies;