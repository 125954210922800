import { useSelector } from 'react-redux';
import { isMobile } from './footer.util';

import './footer.styl';

function Footer() {
  const mediaBreakpoint = useSelector(state => state.appWindow.resizeProps.mediaBreakpoint);

  return (
    <div className="footer">
      <img className="footer_eu_image" src="/img/logo/eu_flag.jpg" alt="EU flag" />
      { isMobile(mediaBreakpoint)
        ? (
          <div className="footer__short__acknowledgment">
            <span>
              Funded by
              {' '}
              <br />
              {' '}
              the European Union
            </span>
          </div>
        )
        : (
          <div>
            
            <span className="footer_acknowledgment">
              The BUILDSPACE project has received funding from the European Union&apos;s 
              Horizon 2023 Research and Innovation Programme under grand agreement No 101082575.
            </span>
          </div>
        ) }
    </div>
  )
}

export default Footer;