import {
  formatDistance, formatArea, getLength, getArea, getPolygonCenterPoint,
} from './measurement.utils';
import { UNITS } from './measurement.constants';
import { addMeasurementPopupToMap, getCenterOfFeature } from './TriggerPopup';

class Measurement {
  constructor(map, control) {
    this.map = map;
    this.control = control;
    this.options = { ...control.options };
    this.style = control.options.style ? { ...control.options.style } : { ...UNITS };
    this._feature = null;
    this._coordinates = [];
    this._minLength = 2;
    this._shape = 'measurement';
    this._hasDistance = false;
    this._hasSurface = false;
    this._finishOnClickLast = false;
    this._finishOnClickFirst = false;
  }

  start(e) {
    this._resetMeasurement(e);
  }

  _resetMeasurement(e) {
    this.coordinates = [];
    this.measurementId = Date.now();
    this._feature = this._createFeature(this.measurementId);
    const source = this.map.getSource('measurement-control');
    source.setData({ ...source._data, features: [...source._data.features, this._feature] });
    this._addStep(e);
  }

  _updateMeasurePoint(lngLat, label) {
    const labelSource = this.map.getSource('measurement-control-labels');
    const labelFeatures = [...labelSource._data.features];
    const oldPointIndex = labelFeatures.findIndex(f => f.properties.measurementId === this.measurementId && f.geometry.type === 'Point');
    const feature = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: lngLat,
      },
      properties: {
        measurementId: this.measurementId,
        geometryType: 'Point',
        ...[label ? [label] : []],
      },
    }
    
    if (oldPointIndex > -1) labelFeatures.splice(oldPointIndex, 1);

    labelSource.setData({ 
      ...labelSource._data, 
      features: [...labelFeatures, feature],
    });
  }

  _addMeasureLine(coordinates) {
    const labelSource = this.map.getSource('measurement-control-labels');
    const labelFeatures = [...labelSource._data.features];
    const feature = {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates,
      },
      properties: {
        measurementId: this.measurementId,
        geometryType: 'LineString',
      },
    }

    if (this.options.displayLength) {
      feature.properties.label = formatDistance(getLength(feature), this.options.unitSystem);
    }

    labelSource.setData({ 
      ...labelSource._data, 
      features: [...labelFeatures, feature],
    });
  }

  _addAreaPoint(polygon) {
    const labelSource = this.map.getSource('measurement-control-labels');
    const labelFeatures = [...labelSource._data.features];
    const oldPointIndex = labelFeatures.findIndex(f => f.properties.measurementId === this.measurementId && f.geometry.type === 'Point');
    const feature = getPolygonCenterPoint(polygon);
    
    feature.properties.measurementId = this.measurementId;
    feature.properties.geometryType = 'Point';
    if (this.options.displayArea) {
      feature.properties.label = formatArea(getArea(polygon), this.options.unitSystem);
    }

    if (oldPointIndex > -1) labelFeatures.splice(oldPointIndex, 1);

    labelSource.setData({ 
      ...labelSource._data, 
      features: [...labelFeatures, feature],
    });
  }

  _addUsabilityDot(coordinates) {
    const dotsSource = this.map.getSource('measurement-control-usability-dots');
    const dotFeature = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates,
      },
      properties: {
        measurementId: this.measurementId,
      },
    }
    dotsSource.setData({ 
      ...dotsSource._data, 
      features: [...dotsSource._data.features, dotFeature],
    });
  }

  _addMeasurementPopupToMap() {
    addMeasurementPopupToMap(
      this.map, 
      this._feature,
      getCenterOfFeature(this._feature, this._feature.properties.shape),
    );
  }
}

export default Measurement;