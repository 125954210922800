/*  EXPORTS
    =============================================================== */
function prepCategories(categories) {
  return categories.map((curr) => { 
    const { accepted, required } = curr;
    
    return { ...curr, accepted: (required || !!accepted) };
  });
}

export {
  prepCategories,
};