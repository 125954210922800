const defaultState = {
  isComparisonModeOn: false,
  exitCompareMode: false,
  secondScenario: null,
  showAreaOnCompareMode: true,
}

const buildingDamages = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_COMPARISON_MODE_STATUS':
      return {
        ...state,
        isComparisonModeOn: action.payload,
      }
    case 'SET_EXIT_COMPARISON_MODE_STATUS':
      return {
        ...state,
        exitCompareMode: action.payload,
      }
    case 'SET_SECOND_SCENARIO':
      return {
        ...state,
        secondScenario: action.payload,
      }
    case 'SET_SHOW_AREA_ON_COMPARE_MODE':
      return {
        ...state,
        showAreaOnCompareMode: action.payload,
      }
    default:
      return state
  }
}

export default buildingDamages;