import './toggle.styl';

function Toggle(props) {
  function handleInputChange(e) {
    props.onChange(e, e.target.checked, e.target.name);
  }

  return (
    <label 
      className={`toggle${props.className ? ` ${props.className}` : ''}`}
      htmlFor={`checkbox__${props.name}`}
    >
      <input 
        type="checkbox" 
        id={`checkbox__${props.name}`}
        className="toggle__input"
        name={props.name}
        checked={!!props.checked}
        onChange={handleInputChange}
      />
      <span className="toggle__label">
        {props.children || props.label}
      </span>
    </label>
  )
}

export default Toggle;