import maplibregl from 'maplibre-gl';

/*  Exports
    ======================================================================================= */
function resizeMap(map) {
  const nw = map.getBounds().getNorthWest();
  map.resize();
  const newNw = map.getBounds().getNorthWest();
  const shiftVector = { x: (newNw.lng - nw.lng), y: (newNw.lat - nw.lat) };

  const center = map.getCenter();
  const newCenter = new maplibregl.LngLat(center.lng - shiftVector.x, center.lat - shiftVector.y);
  map.setCenter(newCenter); // prevents panning
}

export {
  resizeMap,  
}