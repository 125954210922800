function isMobile(mediaBreakpoint) {
  if (mediaBreakpoint === 'XXS' || mediaBreakpoint === 'XXXS' || mediaBreakpoint === 'XS') {
    return true
  }
  
  return false;
}

export {
  isMobile,
}