function toggleControl(controlRefs, isOpen) {
  if (isOpen) {
    controlRefs.forEach(curr => curr.ref._container.classList.add('collapsed'));
  } else {
    controlRefs.forEach(curr => curr.ref._container.classList.remove('collapsed'));
  }
}
  
export {
  toggleControl,
}