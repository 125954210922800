import './about.styl';

function About() {
  return (
    <div className="about">
      {/* <div className="about__content">
        <h1>About page</h1>
        <p>
          This is about page
        </p>
      </div> */}
    </div>
  )
}

export default About;