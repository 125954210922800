import { createPortal } from 'react-dom';

const portalElRef = document.getElementById('js-mapframe-portal');

function Portal(props) {
  const { children } = props;

  return createPortal(children, portalElRef);
}

export default Portal;