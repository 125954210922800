import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@nazka/nazka.mapframe.components';
import { ToastContainer } from 'react-toastify';
import { QueryClientProvider } from '@tanstack/react-query';

import TranslationsProvider, { LocalizedRoutes } from '@utils/i18n/i18n';
import AppWindow from '@utils/AppWindow/AppWindow';
import ErrorBoundary from '@utils/ErrorBoundary/ErrorBoundary';
import CookieBar from '@utils/CookieBar/CookieBar';

import renderRoutes from '@templates/T1/t1.routes';

import { baseRoute, outletRoutes } from '@App/app.routes';
import NoMatch from '@App/pages/NoMatch/NoMatch';
import store from '@App/app.store';
import translator from '@App/app.translator';
import AuthProvider from '@App/components/AuthProvider/AuthProvider';
import queryClient from '@utils/queries/QueryClient';

import theme from '@css/theme.style.json';

import 'react-toastify/dist/ReactToastify.css';
import './app.styl';

function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <ErrorBoundary>
              <AuthProvider>
                <TranslationsProvider translator={translator}>
                  <LocalizedRoutes>
                    {renderRoutes(baseRoute, outletRoutes)}
                    <Route path="*" element={<NoMatch />} />
                  </LocalizedRoutes>
                  <ToastContainer
                    position="top-center"
                    limit={3}
                  />
                  <AppWindow />
                  <CookieBar />
                </TranslationsProvider>
              </AuthProvider>
            </ErrorBoundary>
          </BrowserRouter>
        </ThemeProvider>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
