const defaultState = {
  searchLocation: null,
};

function searchLocation(state = defaultState, action) {
  switch (action.type) {
    case 'SEARCH_LOCATION':
      return {
        ...state,
        searchLocation: action.payload,
      }
    default: 
      return state;
  }
}

export default searchLocation;