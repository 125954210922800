import { useEffect } from 'react';

import { addControlsToMap } from './controls.utils';

import './controls.styl';

function Controls(props) {
  const { map } = props

  useEffect(() => {
    if (map) {
      addControlsToMap(map);
    }
  }, [map])
  
  return null;
}

export default Controls;