const controls = [
  {
    identifier: 'navigation',
    position: 'top-right',
    options: {
      showCompass: true,
      showZoom: true,
    },
  },
  {
    identifier: 'measurement',
    position: 'bottom-right',
    options: {
      shapes: ['circle', 'rectangle', 'polygon'],
      actionsToFinish: ['dblclick', 'esc-key', 'click-first', 'click-last'],
      unitSystem: 'metric',
      fillColor: 'rgba(3, 103, 165, 0.046)',
      lineColor: '#0367A5',
    },
  },
  {
    identifier: 'legend',
    position: 'bottom-right',
    options: {},
  },
  {
    identifier: 'scale',
    position: 'bottom-left',
    visibilityProperty: 'showScaleControl',
    options: {
      unit: 'metric',
    },
  },
  {
    identifier: 'attribution',
    position: 'bottom-left',
    options: {
      compact: true,
      customAttribution: 'Powered by <a href="https://nazka.be" rel="noopener" target="_blank">Nazka Mapps</a>',
    },
  },
]

export {
  controls,
}