// Make sure the category id is unique and isn't a substring of any other category id.

const categories = [
  {
    id: 'essential',
    label: 'Essential',
    description: 'These cookies are necessary for the website to work properly.',
    required: true,
  },
  {
    id: 'functional',
    label: 'Functional',
    description: 'These serve to keep track of your choices and preferences on the website.',
    required: true,
  },
  {
    id: 'advanced-analytics',
    label: 'Advanced analytics',
    description: 'To discover deeper insights and make better predictions we need to process some personal information such as your IP.',
    required: false,
  },
  // {
  //   id: 'marketing',
  //   label: 'Marketing',
  //   description: ('Serve to compile a user profile based on your '
  //    + 'surfing behavior to be used later for marketing purposes.'),
  //   required: false,
  // },
  // {
  //   id: 'social-media',
  //   label: 'Social media links',
  //   description: 'These ensure optimal interaction with social media.',
  //   required: false,
  // },
]

export default categories;