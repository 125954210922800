import { formatCurrency } from '@services/utils.service';
import { Icon } from '@nazka/nazka.mapframe.components';
import { faEquals, faArrowUp, faArrowDown } from '@fortawesome/pro-solid-svg-icons';

import './compare-mode-table.styl'

function CompareModeTable(props) {
  const { scenarioOne, scenarioTwo } = props

  const getIconAndColor = (value) => {
    let color;
    let icon;
  
    if (value < 0) {
      color = 'green';
      icon = faArrowDown;
    } else if (value > 0) {
      color = 'red';
      icon = faArrowUp;
    } else {
      color = '#4691db';
      icon = faEquals;
    }
  
    return { icon, color };
  };

  const calculatePercentage = (value1, value2) => {
    const result = ((value2 - value1) / value1) * 100
    return result.toFixed(2)
  }

  return (
    <div>
      <table className="cost-table__table">
        <thead>
          <tr>
            <th>Class</th>
            <th>Estimated damage scenario 1</th>
            <th>Estimated damage scenario 2</th>
            <th>Change</th>
          </tr>
        </thead>
        <tbody>
          {scenarioOne.map((entry, i) => {
            const valueOne = formatCurrency(entry.value);
            const valueTwo = formatCurrency(scenarioTwo[i].value);
            const percentageChange = calculatePercentage(entry.value, scenarioTwo[i].value);
            const { icon, color } = getIconAndColor(percentageChange);

            return (
              <tr key={entry.name}>
                <td>{entry.name}</td>
                <td>{valueOne}</td>
                <td>{valueTwo}</td>
                <td>  
                  {scenarioTwo[i].value !== 0 ? (
                    <div className="compare-mode-table__changes-text">
                      <span>
                        {percentageChange}
                        %
                      </span>
                      <Icon 
                        icon={icon} 
                        size="10px"
                        color={color}
                      />
                    </div>
                    
                  ) : 'N/A'}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  )
}

export default CompareModeTable