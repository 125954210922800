import Search from '@library/Search/Search';
import { useState } from 'react';
import { useDispatch } from 'react-redux'
import { getSearchAutocompleteData, getSearchPlaceData } from '@services/data.service';

import { faSearch, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { Button } from '@nazka/nazka.mapframe.components'
import { setLocationData } from './location-search.redux.action';

import './location-search.styl'

function LocationSearch() {
  const dispatch = useDispatch();
  const [showFullSearch, setShowFullSearch] = useState(false)

  const SearchGoogleFunction = [
    {
      id: 'searchdata',
      name: 'google',
      onSearch: async (query) => {
        await new Promise((resolve) => { if (query.length >= 3) { resolve('Promise resolved') } })
        const results = getSearchAutocompleteData(query).then((data) => {
          return data.predictions.map(
            (place) => {
              return { 
                id: place.place_id, 
                label: place.description,
              } 
            },
          );
        })
        return results
      },
      onItemSelect: (item) => {
        if (item !== null) {
          getSearchPlaceData(item.id).then((data) => {
            if (data.results) {
              const geometry = data.results[0].geometry.viewport;
              dispatch(setLocationData({
                bbox: [
                  geometry.northeast.lng,
                  geometry.northeast.lat,
                  geometry.southwest.lng,
                  geometry.southwest.lat,
                ],
                center: data.results[0].geometry.location, 
              }))
            }
          })
        }

        // Callback called when items are selected from the list
        // history.push(`/map/${encodeURIComponent(item.label)}`);
      }, 
    },
  ];

  return (
    <div className="location-search">
      {showFullSearch && (
        <div className="location-search__container z-depth-1">
          <Search dataSources={SearchGoogleFunction} />
        </div>
      )}
      
      <Button
        icon={showFullSearch ? faXmark : faSearch}
        color="white"
        css={{
          borderRadius: '3px',
        }}
        onClick={() => setShowFullSearch(prevState => !prevState)}
      />
    </div>
  )
}

export default LocationSearch;
