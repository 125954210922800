import store from '@App/app.store';
import { setActiveShape } from '../../../../../maplibre-map.redux.actions';
import MeasurementPolyline from './MeasurementPolyline';
import {
  formatDistance, formatArea, getLength, getArea, removeFeature, 
} from './measurement.utils';
import { getColor } from '../measurement-control.layers';

class MeasurementPolygon extends MeasurementPolyline {
  constructor(map, featureGroup, options, style) {
    super(map, featureGroup, options, style);
    this._shape = 'polygon';
    this._hasDistance = true;
    this._hasSurface = true;
    this._finishOnClickLast = this.options.actionsToFinish.includes('click-last');
    this._finishOnClickFirst = this.options.actionsToFinish.includes('click-first');
  }

  commit() {
    // cleanup polygon after doubleclick
    if (this.coordinates.length > 3 
      && JSON.stringify(this.coordinates[this.coordinates.length - 2])
      === JSON.stringify(this.coordinates[this.coordinates.length - 3])) {
      this.coordinates.splice(this.coordinates.length - 3, 1);
    }

    if (this.coordinates.length > 3) {
      const source = this.map.getSource('measurement-control');
      const feature = source._data.features.pop();
      const perimeter = formatDistance(getLength(feature), this.options.unitSystem);
      const surface = formatArea(getArea(feature), this.options.unitSystem);

      feature.geometry.coordinates = [this.coordinates];
      feature.properties.perimeter = perimeter;
      feature.properties.surface = surface;

      const area = getArea(feature) / 10000
      getColor(area, this.map, feature, this.options)

      source.setData({ 
        ...source._data, 
        features: [...source._data.features, feature],
      });
      this._addMeasureLine([
        this.coordinates[this.coordinates.length - 1], 
        this.coordinates[this.coordinates.length - 2],
      ]);
      this._addAreaPoint(feature);
    } else {
      removeFeature(this.map, this.measurementId);
    }

    const labelSource = this.map.getSource('measurement-control-labels');
    const labelFeatures = [...labelSource._data.features].filter((f) => {
      return f.properties.measurementId !== `${this.measurementId}_temp`;
    });
    labelSource.setData({ 
      ...labelSource._data, 
      features: [...labelFeatures],
    });

    this.shape = null;
  }

  _createFeature(id) {
    return {
      type: 'Feature',
      id,
      geometry: {
        type: 'Polygon',
        coordinates: this.coordinates,
      },
      properties: {
        shape: 'polygon',
      },
    }
  }

  _addStep(e) {
    let { lngLat } = e;
    lngLat = [lngLat.lng, lngLat.lat];
    const { map } = this;

    if (this._stopMeasurementConditionsMet(lngLat)) {
      this.map.fire('measurement-commit', { lngLat: e.lngLat, control: this.control });
      this.map.fire('measurement-end', { map, control: this.control });
      store.dispatch(setActiveShape(null))
    } else {
      if (this.coordinates.length) this.coordinates.pop();
      this.coordinates = [...this.coordinates, lngLat];
      this.coordinates.push(this.coordinates[0]);
      const source = this.map.getSource('measurement-control');
      const feature = source._data.features.pop();

      feature.geometry.coordinates = [this.coordinates];
      source.setData({ 
        ...source._data, 
        features: [...source._data.features, feature],
      });

      this._addUsabilityDot(lngLat);

      if (this.coordinates.length > 2) {
        this._addMeasureLine([
          this.coordinates[this.coordinates.length - 2], 
          this.coordinates[this.coordinates.length - 3],
        ]);
      }
      if (this.coordinates.length > 3) {
        this._addAreaPoint(feature);
      }
    }
  }

  _render(e) {
    const source = this.map.getSource('measurement-control');
    const feature = source._data.features.pop();
    const coordinates = [...this.coordinates];
    coordinates.pop();
    coordinates.push([e.lngLat.lng, e.lngLat.lat]);
    if (coordinates.length > 2) coordinates.push(coordinates[0]);
    feature.geometry.coordinates = [coordinates];

    source.setData({ ...source._data, features: [...source._data.features, feature] });
    
    if (coordinates.length > 1) {
      this._addTempMeasureLines(coordinates);
    }
  }

  _addTempMeasureLines(coordinates) {
    const labelSource = this.map.getSource('measurement-control-labels');
    const labelFeatures = [...labelSource._data.features].filter((f) => {
      return f.properties.measurementId !== `${this.measurementId}_temp`;
    });
    let feature = null;
    if (coordinates.length > 2) {
      feature = {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            coordinates[coordinates.length - 2], 
            coordinates[coordinates.length - 3],
          ],
        },
        properties: {
          measurementId: `${this.measurementId}_temp`,
          geometryType: 'LineString',
        },
      }
      if (this.options.displayLength) {
        feature.properties.label = formatDistance(getLength(feature), this.options.unitSystem);
      }
    }

    const lastFeature = {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [
          coordinates[coordinates.length - 1], 
          coordinates[coordinates.length - 2],
        ],
      },
      properties: {
        measurementId: `${this.measurementId}_temp`,
        geometryType: 'LineString',
      },
    }
    if (this.options.displayLength) {
      lastFeature.properties.label = formatDistance(
        getLength(lastFeature), 
        this.options.unitSystem,
      );
    }

    let features = []
    if (feature) {
      features = [...labelFeatures, feature, lastFeature];
    } else {
      features = [...labelFeatures, lastFeature];
    }

    labelSource.setData({ ...labelSource._data, features });
  }

  _stopMeasurementConditionsMet(lngLat) {
    const lastPoint = this.map.project(lngLat);
    const bb = [
      [lastPoint.x - 10, lastPoint.y - 10],
      [lastPoint.x + 10, lastPoint.y + 10],
    ];

    if (this._finishOnClickLast && this.coordinates.length) {
      const prevPoint = this.map.project(this.coordinates[this.coordinates.length - 2]);
      if (bb[0][0] <= prevPoint.x && prevPoint.x <= bb[1][0] 
        && bb[0][1] <= prevPoint.y && prevPoint.y <= bb[1][1]) {
        return true;
      }
    }

    if (this._finishOnClickFirst && (this.coordinates.length > 3)) {
      const firstPoint = this.map.project(this.coordinates[0]);
      if (bb[0][0] <= firstPoint.x && firstPoint.x <= bb[1][0] 
        && bb[0][1] <= firstPoint.y && firstPoint.y <= bb[1][1]) {
        return true;
      }
    }
    return false;
  }
}

export default MeasurementPolygon;