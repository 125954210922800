import { useEffect } from 'react';

import { useMunicipalities } from '@utils/queries/map.queries';

const Municipality = ({ map }) => {
  const { data: municipalities } = useMunicipalities();

  useEffect(() => {
    map.addLayer({
      id: 'municipalities',
      type: 'line',
      source: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      },
      paint: {
        'line-color': '#022741',
        'line-width': 2,
      },
    }, 'z-index-1');

    return () => {
      if (map && !map._removed) {
        if (map.getLayer('municipalities')) {
          map.removeLayer('municipalities');
        }
  
        if (map.getSource('municipalities')) {
          map.removeSource('municipalities');
        }
      }
    };
  }, [map]);

  useEffect(() => {
    if (municipalities) {
      map.getSource('municipalities').setData(municipalities);
    }
  }, [map, municipalities]);
}

export default Municipality