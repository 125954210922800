import React from 'react';

import { formatCurrency } from '@services/utils.service';

import './cost-table.styl';

function CostTable({ data }) {
  return (
    <div className="cost-table">
      <h3>
        Cost per class
      </h3>
      <table className="cost-table__table">
        <thead>
          <tr>
            <th>Class</th>
            <th>Estimated damage costs</th>
          </tr>
        </thead>
        <tbody>
          {data.map((entry) => {
            return (
              <tr key={entry.name}>
                <td>{entry.name}</td>
                <td>{formatCurrency(entry.value)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default CostTable;