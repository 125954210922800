import React, { useState } from 'react';
// import extent from 'turf-extent';
import { useMutation } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';
import { toast } from 'react-toastify';
import { Loader, Input, Button } from '@nazka/nazka.mapframe.components';

import queryClient from '@utils/queries/QueryClient';
import { setCurrentSelectedDamageLayer } from '@App/components/LayerSelector/layer-selector.redux.action';
import { useUserSettings } from '@utils/queries/user.queries';

import { setShowActiveDrawingLayer } from '@library/MaplibreMap/maplibre-map.redux.actions';
import { removeAllFeatures } from '../Measurement/measurement.utils';

import './measurement-popup.styl';

function MeasurementPopup(props) {
  const {
    map, feature, popup, areaId, 
  } = props
  const [description, setDescription] = useState();

  const isRangeExceeded = useSelector(state => state.maplibreMap.exceededRange)
  const { data: userSettings } = useUserSettings();
  const dispatch = useDispatch();

  const {
    floodMeasures,
    floodYear,
  } = userSettings || {};

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async () => {
      const payload = areaId ? {
        model: 'default',
        areaId,
        description: description || 'New damage layer',
      } : {
        model: 'default',
        area: feature,
        areaName: 'New area',
        description: description || 'New damage layer',
      }

      return axios.post('/api/calculation/damage', payload).then(result => result.data)
    },
    onSuccess: () => {
      popup.remove();
      dispatch(setShowActiveDrawingLayer(true))
      toast.success('Successfully created your damage calculation');
    },
    onSettled: (data) => {
      queryClient.invalidateQueries({ queryKey: ['user', 'flood-damages'] });
      queryClient.invalidateQueries({ queryKey: ['user', 'areas'] });
      dispatch(setCurrentSelectedDamageLayer(data))
    },
  });

  if (isPending) {
    return (
      <div className="measurement-popup">
        <Loader
          css={{
            height: '40px',
            width: '40px',
          }}
        />
        <span className="measurement-popup__text">
          Creating your damage calculation
        </span>
      </div>
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    removeAllFeatures(map)
    mutate();
  }

  if (isError) {
    return (
      <div className="measurement-popup">
        <span className="measurement-popup__error__text">
          Something went wrong creating your damage calculation
          <Button
            className="measurement-popup__buttons__button warning__button"
            onClick={() => {
              removeAllFeatures(map)
              popup.remove();
            }}
            hoverColor="#0367a5dd"
            css={{
              height: '30px',
              fontSize: '14px',
              lineHeight: 0,
              borderRadius: '3px',
            }}
          >
            Reset
          </Button>
        </span>
      </div>
    );
  }

  if (isRangeExceeded) {
    return (
      <div className="measurement-popup">
        <span className="measurement-popup-warning">
          Your selected area exceeds the size limit of 1 km². Please draw a new area.
        </span>
        <Button
          className="measurement-popup__buttons__button warning__button"
          onClick={() => {
            removeAllFeatures(map)
            popup.remove();
          }}
          hoverColor="#0367a5dd"
          css={{
            height: '30px',
            fontSize: '14px',
            lineHeight: 0,
            borderRadius: '3px',
          }}
        >
          Reset
        </Button>
      </div>
    )
  }

  return (
    <div className="measurement-popup">
      <h3 className="measurement-popup__title">
        New damage layer
      </h3>
      <p>
        Do you want to create a new damage layer with the flood scenario 
        <strong>
          {' '}
          {floodYear}
          -year flood,
          {' '}
          {floodMeasures ? (
            <span>
              {floodMeasures === '_Measures' ? 'anti-flood measures' : 'climate change'}
            </span>
          ) : (
            null
          )}
        </strong>
        {' '}
        with the land use features(s): 
        {' '}
        <strong>Building</strong>
        ?
      </p>
      <form onSubmit={handleSubmit}>
        <label htmlFor="description">
          <span>
            Damage layer name :
          </span>
          <Input
            name="description"
            rootElProps={{
              id: 'description',
              placeholder: 'Name',
            }}
            onInputChange={e => setDescription(e.target.value)}
            css={{
              border: '1px solid #ddd',
              borderRadius: '4px',
            }}
          />
        </label>
        <div className="measurement-popup__buttons">
          <Button
          // className="measurement-popup__buttons__button confirmation__button"
            isLoading={isPending}
            hoverColor="#0367a5dd"
            css={{
              height: '30px',
              fontSize: '14px',
              lineHeight: 0,
              borderRadius: '3px',
            }}
            type="submit"
          >
            Confirm
          </Button>
          <Button
          // className="measurement-popup__buttons__button reset__button"
            onClick={() => {
              removeAllFeatures(map);
              popup.remove();
            }}
            layout="outlined"
            hoverColor="#0367a5dd"
            css={{
              height: '30px',
              fontSize: '14px',
              lineHeight: 0,
              borderRadius: '3px',
              border: '1px solid #0367a554',
            }}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  )
}

export default MeasurementPopup;