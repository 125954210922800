import { createRoot } from 'react-dom/client';
import store from '@App/app.store'
import { Provider } from 'react-redux';

import Legend from '@App/components/Legend/Legend';
import { ThemeProvider } from '@nazka/nazka.mapframe.components';
import theme from '@css/theme.style.json'

class MapLegend {
  constructor(options) {
    this.container = null;
    this.controlRefs = options.controlRefs;
  }

  onAdd() {
    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group mapboxgl-ctrl-measure';
 
    const root = createRoot(this.container);
    root.render(
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Legend />
        </ThemeProvider>
      </Provider>,
    )
   
    return this.container;
  }
  
  onRemove() {
    this.container.parentNode.removeChild(this.container);
  }
}

export default MapLegend