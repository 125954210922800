import MeasurementPolyline from './Measurement/MeasurementPolyline';
import MeasurementPolygon from './Measurement/MeasurementPolygon';
import MeasurementRectangle from './Measurement/MeasurementRectangle';
import MeasurementCircle from './Measurement/MeasurementCircle';

function onMeasurementStart(e) {
  const { map, shape, control } = e;
  control.shape = shape;
  map.getCanvas().style.cursor = 'crosshair';
}

function onMeasurementInit(e) {
  const { map, control, lngLat } = e;

  switch (control.shape) {
    case 'polyline':
      control.measurement = new MeasurementPolyline(map, control);
      break;
    case 'rectangle':
      control.measurement = new MeasurementRectangle(map, control);
      break;
    case 'polygon':
      control.measurement = new MeasurementPolygon(map, control);
      break;
    case 'circle':
      control.measurement = new MeasurementCircle(map, control);
      break;
    default: 
      control.measurement = new MeasurementPolyline(map, control);
      break;
  }

  control.measurement.start({ lngLat });
  if (map.doubleClickZoom.isEnabled()) {
    this.clickZoomWasEnabled = true;
  }
  map.doubleClickZoom.disable();
}

function onMeasurementUpdate(e) {
  const { control, lngLat } = e;
  control.measurement._addStep({ lngLat });
}

function onMeasurementCommit(e) {
  const { control, lngLat } = e;

  if (control.measurement) {
    control.measurement.commit();
    control.measurement._addMeasurementPopupToMap(lngLat);
    control.measurement = null;
  }
}

function onMeasurementEnd(e) {
  const { map, control } = e;
  
  map.setMinZoom(8)

  if (control.measurement) {
    control.measurement.commit();
    control.measurement = null;
  }

  control.shape = null;

  map.getCanvas().style.cursor = '';

  if (this.clickZoomWasEnabled) {
    map.doubleClickZoom.enable();
  }
}

export { 
  onMeasurementStart,
  onMeasurementInit,
  onMeasurementUpdate,
  onMeasurementCommit,
  onMeasurementEnd,
};