const setComparisonModeStatus = (status) => {
  return {
    type: 'SET_COMPARISON_MODE_STATUS',
    payload: status,
  }
}

const setExitComparisonModeStatus = (status) => {
  return {
    type: 'SET_EXIT_COMPARISON_MODE_STATUS',
    payload: status,
  }
}

const setSecondScenario = (secondScenario) => {
  return {
    type: 'SET_SECOND_SCENARIO',
    payload: secondScenario,
  }
}

const setShowAreaOnCompareMode = (status) => {
  return {
    type: 'SET_SHOW_AREA_ON_COMPARE_MODE',
    payload: status,
  }
}

export {
  setComparisonModeStatus,
  setSecondScenario,
  setShowAreaOnCompareMode,
  setExitComparisonModeStatus,
}