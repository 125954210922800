import { useDispatch, useSelector } from 'react-redux';

import { setActiveShape } from '@library/MaplibreMap/maplibre-map.redux.actions';
import { removeAllFeatures } from '../Measurement/measurement.utils';

import './measurement-select-type.styl';

function MeasurementSelectType(props) {
  const { map, parent, options } = props;
  const activeShape = useSelector(state => state.maplibreMap.shape);
  const activePopup = useSelector(state => state.maplibreMap.popup)

  const dispatch = useDispatch();

  return (
    <div className="measurement-select-type z-depth-1">
      { options.shapes.map(shape => (
        <button
          type="button"
          title={`Draw ${shape}`}
          className={
            `measurement-select-type__button measurement-select-type__button--${shape}`
            + `${activeShape && activeShape === shape ? ' active' : ''}`
          }
          key={shape}
          onClick={() => {
            if (activeShape !== shape) {
              activePopup?.remove()
              removeAllFeatures(map)
              map.setMinZoom(15)
              dispatch(setActiveShape(shape));
              map.fire('measurement-start', { shape, map, control: parent });
            } else {
              dispatch(setActiveShape(null));
              map.fire('measurement-end', { map, control: parent });
            }
          }}
        />
      ))}
    </div>
  );
}

export default MeasurementSelectType;