const defaultState = {
  showData: false,
};

function t1(state = defaultState, action) {
  switch (action.type) {
    case 'SET_SHOW_DATA':
      return { 
        ...state, 
        showData: action.payload,
      }
    default:
      return state;
  }
}

export default t1;