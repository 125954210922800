import BasePage from '@App/pages/BasePage/BasePage';
import About from '@App/pages/About/About';
import Contact from '@App/pages/Contact/Contact';

const baseRoute = {
  name: 'Map', 
  path: '/', 
  element: <BasePage />,
};

const outletRoutes = [
  { 
    name: 'About', 
    path: '/about', 
    element: <About />, 
  },
  { 
    name: 'Contact', 
    path: '/contact', 
    element: <Contact />, 
  },
];

export {
  baseRoute,
  outletRoutes,
};