import { createRoot } from 'react-dom/client';
import { UserbackProvider } from '@userback/react';
import App from './App/App';

import './index.styl';

const container = document.getElementById('js-mapframe');
const root = createRoot(container);

root.render(
  <UserbackProvider token={import.meta.env.VITE_USERBACK_KEY}>
    <App />
  </UserbackProvider>,
);