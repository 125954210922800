import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

function useMunicipalities(country = 'slovenia') {
  return useQuery({
    queryKey: ['map', 'boundaries'],
    queryFn: async () => {
      const { data } = await axios.get(
        `https://boundaries-api.io/api/${country}/geometry/find/level?level=1&detail=2`,
      );

      return {
        type: 'FeatureCollection',
        features: data.geometries.features,
      };
    },
  });
}

export {
  useMunicipalities,
};