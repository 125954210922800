import { useSelector, useDispatch } from 'react-redux'

import { Toggle } from '@nazka/nazka.mapframe.components'
import { useSpecificArea } from '@utils/queries/area.queries';
import Radio from '../LayerSelector/CustomRadiogroup/Radio/Radio';
import { setShowAreaOnCompareMode } from '../BuildingDamages/building-damages.redux.actions';

function CompareModeLayerPanelFloodDamageSection() {
  const dispatch = useDispatch()
  const showDamageArea = useSelector(state => state.buildingDamages.showAreaOnCompareMode)
  const firstScenario = useSelector(state => state.layerSelector.currentSelectedDamageLayer)
  const secondScenario = useSelector(state => state.buildingDamages.secondScenario)

  const { data: specificArea } = useSpecificArea(firstScenario.areaId)
  return (
    <div>
      <Toggle 
        label={specificArea.areaName}
        name={specificArea.areaName}
        checked={showDamageArea}
        activeColor="#0367A5"
        css={{
          fontSize: '14px',
          input: { fontSize: '12px' },
        }}
        onInputChange={() => dispatch(setShowAreaOnCompareMode(!showDamageArea))}
      />
      <div style={{ paddingLeft: '6px' }}>
        <Radio label={firstScenario.description} value={firstScenario.id} checked />
        {secondScenario && <Radio label={secondScenario.description} value={secondScenario.id} checked />}
      </div>
    </div>
  )
}

export default CompareModeLayerPanelFloodDamageSection