import maplibregl from 'maplibre-gl';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { QueryClientProvider } from '@tanstack/react-query';
import centroid from '@turf/centroid';
import { center } from '@turf/turf';
import theme from '@css/theme.style.json';
import { ThemeProvider } from '@nazka/nazka.mapframe.components'

import appStore from '@App/app.store';
import queryClient from '@utils/queries/QueryClient';

import MeasurementPopup from '../MeasurementPopup/MeasurementPopup';
import { setPopup } from '../../../../../maplibre-map.redux.actions';

function addMeasurementPopupToMap(map, feature, centerOfFeature, areaId) {
  const popup = new maplibregl.Popup({ 
    className: 'mapbox-measurement-popup',
    closeButton: false,
    focusAfterOpen: true,
    anchor: 'left',
  });

  const popupEl = document.createElement('div');
  const root = createRoot(popupEl)
      
  root.render(
    <Provider store={appStore}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <MeasurementPopup 
            feature={feature}
            popup={popup}
            map={map}
            areaId={areaId || null}
          />
        </ThemeProvider>
      </QueryClientProvider>
    </Provider>,
  );
  
  popup
    .setLngLat(centerOfFeature)
    .setDOMContent(popupEl)
    .addTo(map);
    
  appStore.dispatch(setPopup(popup))
}

function getCenterOfFeature(feature, shape) {
  let point;
  
  switch (shape) {
    case 'circle':
      point = center(feature);
      break;
    default:
      point = centroid(feature);
  }
  
  return point.geometry.coordinates;
}

export {
  addMeasurementPopupToMap,
  getCenterOfFeature,
}