import { Icon } from '@nazka/nazka.mapframe.components';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

import Tooltip from '@library/Tooltip/Tooltip';

const mainOptions = [
  { value: '10', label: '10-year flood' },
  { value: '100', label: '100-year flood' },
  { value: '500', label: '500-year flood' },
];

const secondaryOptions = [
  { value: null, label: 'No scenario' },
  {
    value: '_Measures',
    label:
  <div className="tooltip__element__measure">
    <span>Anti-flood measures (-50m buffer)</span>
    {/* <Tooltip
      id="Measures"
      tooltip="To simulate anti-flood measures, a 50m inwards buffer was calculated to limit the flood extent"
      place="right"
      className="tooltip"
    >
      <span data-tooltip-id="Measures">
        <Icon
          icon={faInfoCircle}
          size="14px"
          color="grey"
        />
      </span>
    </Tooltip> */}
  </div>,
  },
  {
    value: '_CC',
    label:
  <div className="tooltip__element__cc">
    <span>Climate change (+30m buffer)</span>
    {/* <Tooltip
      id="CC"
      tooltip="To simulate the increase in flooding expected with climate change, 
      a 30m outwards buffer was calculated to expand the flood extent."
      place="right"
      className="tooltip"
    >
      <span data-tooltip-id="CC">
        <Icon
          icon={faInfoCircle}
          size="14px"
          color="grey"
        />
      </span>
    </Tooltip> */}
  </div>,
  },
];

export {
  mainOptions,
  secondaryOptions,
};