import './contact.styl';

function Contact() {
  return (
    <div className="contact">
      {/* <div className="contact__content">
        <h1>Contact page</h1>
        <p>
          this is Contact page
        </p>
      </div> */}
    </div>
  )
}

export default Contact;