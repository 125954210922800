import axios from 'axios';

function fetchFile(href, onDownloadProgress) {
  return axios.get(href, { 
    responseType: 'blob',
    onDownloadProgress: (progressEvent) => {
      onDownloadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
    },
  })
}

function getSearchAutocompleteData(input) {
  return axios.get(`/api/search/autocomplete?requiredParam=${input}`)
    .then(result => result.data);
}

function getSearchPlaceData(input) {
  return axios.get(`/api/search/geocode?requiredParam=${input}`)
    .then(result => result.data);
}

export {
  fetchFile,
  getSearchAutocompleteData,
  getSearchPlaceData,
}