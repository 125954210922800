import { useState, useEffect, useCallback } from 'react';
import queryClient from '@utils/queries/QueryClient';

import { useLocalStorage } from '@services/hooks.service';
import { refreshTokens, signOut, testToken } from '@services/auth.service';
import { useUserDetails } from '@utils/queries/user.queries';

import AuthProviderContext from './auth-provider.context';
import LoginModal from '../LoginModal/LoginModal';

import { registerAxiosInterceptor } from './auth-provider.utils';

function AuthProvider({ children }) {
  const [checkedAuthentication, setCheckedAuthentication] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [rememberMe, setRememberMe] = useLocalStorage('rememberMe', false);
  const [registeredAxiosInterceptor, setRegisteredAxiosInterceptor] = useState(false);

  const { data: userDetails } = useUserDetails(isLoggedIn);

  const logOut = useCallback(async () => {
    queryClient.removeQueries({ queryKey: ['user'] });
    await signOut();
    setIsLoggedIn(false);
    setRememberMe(false);
  }, [setRememberMe]);

  const logIn = useCallback((userWantsToBeRemembered) => {
    setIsLoggedIn(true);
    setRememberMe(userWantsToBeRemembered);
  }, [setRememberMe]);

  function onAuthCompletion() {
    document.documentElement.setAttribute('data-app-initialised', true);
    setCheckedAuthentication(true);
  }

  useEffect(() => {
    if (!registeredAxiosInterceptor) {
      setRegisteredAxiosInterceptor(true);
      registerAxiosInterceptor(logOut);
    }
  }, [logOut, registeredAxiosInterceptor]);

  useEffect(() => {
    if (!checkedAuthentication) {
      if (!rememberMe) {
        testToken()
          .then(() => setIsLoggedIn(true))
          .finally(onAuthCompletion);
      } else {
        testToken()
          .then(() => setIsLoggedIn(true))
          // user access token invalid => check if we can use refresh token
          .catch(() => refreshTokens().then(() => setIsLoggedIn(true)))
          .finally(onAuthCompletion);
      }
    }
  }, [rememberMe, checkedAuthentication]);

  return (
    <AuthProviderContext
      value={{
        isLoggedIn,
        logIn,
        logOut,
        userDetails,
      }}
    >
      {children}
      {checkedAuthentication && !isLoggedIn && <LoginModal />}
    </AuthProviderContext>
  );
}

export default AuthProvider;