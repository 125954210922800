import Radio from './Radio/Radio';

import './radiogroup.styl';

function Radiogroup(props) {
  const {
    className, name, radios, selectedValue, onChange, disabled,
  } = props;

  return (
    <div className={`radiogroup ${className || ''}`}>
      { 
        radios.map((radio) => {
          return (
            <Radio 
              key={radio.value}
              name={name}
              label={radio.label}
              value={radio.value}
              checked={selectedValue === radio.value}
              onChange={e => onChange(e.target.value, selectedValue)}
              disabled={disabled}
            />
          );
        }) 
      }
    </div>
  );
}

export default Radiogroup;