import { useLocation, NavLink } from 'react-router-dom';
import { useState } from 'react';
import { Button } from '@nazka/nazka.mapframe.components'
import { faCircleUser } from '@fortawesome/pro-solid-svg-icons'
import { useSelector } from 'react-redux';
import { isMobile } from '../Footer/footer.util'; 

import './navbar.styl';
import MobileNavbar from './MobileNavbar/MobileNavbar';
import { useAuth } from '../../../components/AuthProvider/auth-provider.context';

function Navbar() {
  const { userDetails, isLoggedIn, logOut } = useAuth()
  const location = useLocation();
  const mediaBreakpoint = useSelector(state => state.appWindow.resizeProps.mediaBreakpoint);
  const [isOpen, setIsOpen] = useState(false)

  const toggleIsOpen = () => {
    setIsOpen(prevState => !prevState);
  }
  const handleSignOut = () => {
    logOut();
    setIsOpen(prevState => !prevState);
  }

  return (
    <div className="navbar z-depth-1">
      <div className="navbar__betaversion">
        <NavLink to="/" className="navbar__logo">
          <img src="/img/logo/main-logo.png" alt="logo" />
        </NavLink>
        <span>Betaversion</span>
      </div>
      
      {isMobile(mediaBreakpoint) 
        ? (<MobileNavbar />)
        : (
          <>
           
            <span style={{ flex: 1 }} />
            {/* {(location.pathname !== '/') && <NavLink to="/">Map</NavLink>} */}
            <NavLink to="/" className={`${location.pathname === '/' ? 'active' : ''}`}>Map</NavLink>
            <NavLink to="/about" className={`${location.pathname === '/about' ? 'active' : ''}`}>About</NavLink>
            <NavLink to="/contact" className={`${location.pathname === '/contact' ? 'active' : ''}`}>Contact</NavLink>
            <Button 
              icon={faCircleUser}
              hover={false}
              css={{
                color: '#0367A5',
                backgroundColor: 'white',
                border: 'none',
                padding: '0 0 0 12px',
              }}
              iconProps={{
                size: '26px',
              }}
              onClick={toggleIsOpen}
            />
            {isLoggedIn && isOpen && (
              <div className="navbar__user__detail z-depth-1">
                <div className="navbar__user__detail__elements">
                  <span>{userDetails?.email}</span>
                  <Button
                    className="navbar__user__detail__button"
                    type="submit"
                    css={{
                      marginTop: '10px',
                      width: '100px',
                      borderRadius: '4px',
                    }}
                    onClick={handleSignOut}
                  >
                    Log out
                  </Button>
                </div>
              
              </div>
            )}
            
          </>
        )}
      
    </div>
  )
}

export default Navbar;