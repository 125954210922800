import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { useAuth } from '@App/components/AuthProvider/auth-provider.context';

const useSpecificArea = (id) => {
  const { isLoggedIn } = useAuth()

  return useQuery({
    queryKey: ['area', 'specific-area', id],
    queryFn: async () => {
      const { data } = await axios.get(`/api/user/areas/${id}`);
      return data;
    },
    enabled: id && isLoggedIn,
  });
}

export {
  useSpecificArea,
}