const metricLengthUnits = {
  meters: {
    factor: 1,
    display: 'm',
    decimals: 0,
  },
  kilometers: {
    factor: 0.001,
    display: 'km',
    decimals: 2,
  },
}
    
const metricAreaUnits = {
  sqmeters: {
    factor: 1,
    display: 'm²',
    decimals: 0,
  },
  sqkilometers: {
    factor: 0.000001,
    display: 'km²',
    decimals: 2,
  },
  hectares: {
    factor: 0.0001,
    display: 'ha',
    decimals: 2,
  },
}
    
const imperialLengthUnits = {
  feet: {
    factor: 3.2808,
    display: 'ft',
    decimals: 0,
  },
  miles: {
    factor: 3.2808 / 5280,
    display: 'mi',
    decimals: 2,
  },
}
    
const imperialAreaUnits = {
  sqfeet: {
    factor: 10.7639,
    display: 'ft²',
    decimals: 0,
  },
  acres: {
    factor: 0.00024711,
    display: 'ac',
    decimals: 2,
  },
  sqmiles: {
    factor: 0.000000386102,
    display: 'mi²',
    decimals: 2,
  },
}
    
const UNITS = { 
  ...metricLengthUnits, 
  ...imperialLengthUnits, 
  ...metricAreaUnits, 
  ...imperialAreaUnits, 
};

export {
  metricLengthUnits,
  metricAreaUnits,
  imperialLengthUnits,
  imperialAreaUnits,
  UNITS,
}