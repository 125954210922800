import { useLocation, NavLink } from 'react-router-dom';
import './mobile-navbar.styl';

function MobileNavbar() {
  const location = useLocation();
  return (
    <nav className="menu--right" role="navigation">
      <div className="menuToggle">
        <input type="checkbox" />
        <span />
        <span />
        <span />
        <ul className="menuItem">
          <li><NavLink to="/" className={`${location.pathname === '/' ? 'active' : ''}`}>Map</NavLink></li>
          <li><NavLink to="/about" className={`${location.pathname === '/about' ? 'active' : ''}`}>About</NavLink></li>
          <li><NavLink to="/contact" className={`${location.pathname === '/contact' ? 'active' : ''}`}>Contact</NavLink></li>
        </ul>
      </div>
    </nav>
  )
}

export default MobileNavbar