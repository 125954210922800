import { Route, Outlet } from 'react-router-dom';

function renderRoutes(baseRoute, outletRoutes) {
  return (
    <Route 
      path={baseRoute.path}
      element={(
        <div className="t1">
          {baseRoute.element}
          <Outlet />
        </div>
      )}
    >
      { outletRoutes.map((route) => {
        return (
          <Route
            key={route.path}
            path={route.path}
            element={<div className="t1__outlet">{route.element}</div>}
          />
        )
      })}
    </Route>
  );
}

export default renderRoutes;