import {
  useState, useMemo, useRef, 
} from 'react';
import { useCombobox } from 'downshift';
import debounce from 'lodash.debounce';

import { Icon } from '@nazka/nazka.mapframe.components';
import IconButton from '@library/IconButton/IconButton';
import { faSearch, faX } from '@fortawesome/pro-light-svg-icons';

import './search.styl';

function Search(props) {
  const { contrast, inputDebounceMs = 500, dataSources } = props;
  const [items, setItems] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const inputElRef = useRef();

  const onInputValueChange = useMemo(() => debounce(({ inputValue }) => {
    setItems([]);

    if (!inputValue || (inputValue.length < 2)) {
      return;
    }

    dataSources.forEach(async (dataSource) => {
      const { index, onSearch } = dataSource;

      const dataSourceItems = await onSearch(inputValue);
    
      setItems((prevItems) => {
        return [
          ...prevItems.filter(item => (item.sourceIndex !== index)),
          ...dataSourceItems.map(item => ({ ...item, sourceIndex: index })),
        ].sort((x, y) => (x.sourceIndex - y.sourceIndex));
      });
    })
  }, inputDebounceMs), [inputDebounceMs, dataSources]);

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getItemProps,
    highlightedIndex,
    selectedItem,
    selectItem,
  } = useCombobox({
    items,
    itemToString: item => (item ? item.label : ''),
    // onInputValueChange: onInputValueChangeRef.current,
    onInputValueChange,
    onSelectedItemChange: ({ selectedItem: newlySelectedItem }) => {
      // when selection is cleared, newlySelectedItem will be null 
      const { sourceIndex } = (newlySelectedItem || selectedItem);
      const dataSource = dataSources.find(curr => (curr.index === sourceIndex));

      dataSource.onItemSelect(newlySelectedItem, selectedItem);
      selectItem(null);
      setIsFocused(!isFocused);
    },
  })

  const handleInputFocus = () => {
    if (isFocused) {
      selectItem(null)
    }
    setIsFocused(!isFocused);
  };

  return (
    <div className={`search ${contrast ? 'search--contrast' : ''}`}>
      <div className="search__query">
        <Icon icon={faSearch} size="18px" />
        <input {...getInputProps({ className: 'search__query__input', ref: inputElRef, placeholder: 'Search...' })} onFocus={handleInputFocus} />
        {!isFocused ? null : (
          <IconButton 
            icon={faX}
            onClick={handleInputFocus}
            iconSize="14px"
            rootElProps={{ padding: '4px' }}
            className="search__query__input__icon-button"
          />
        )}
      </div>
      <ul {...getMenuProps({ className: 'search__menu z-depth-1' })}>
        { isOpen
          && items.map((item, index) => (
            <li
              key={`${item.sourceIndex}-${item.id}`}
              {...getItemProps({
                className: `
                    search__menu__item
                    ${(highlightedIndex === index) ? 'search__menu__item--highlighted' : ''}
                    ${(selectedItem && (selectedItem.sourceIndex === item.sourceIndex) && (selectedItem.id === item.id)) ? 'search__menu__item--selected' : ''}
                    ellipsis
                  `,
                item,
                index,
              })
              }
            >
              <span>{item.label}</span>
            </li>
          ))}
      </ul>
    </div>
  );
}

export default Search;
