import { createRoot } from 'react-dom/client';

import MoreToggle from '@library/MaplibreMap/controls/Controls/CustomControls/MoreToggle/MoreToggle';
import TranslationsProvider from '@utils/i18n/i18n';

import translator from '@App/app.translator';

import './more-control.styl';

class MoreControl {
  constructor(options) {
    this.container = null;
    this.controlRefs = options.controlRefs;
    this.isOpen = false;
  }

  onAdd(map) {
    this.container = document.createElement('div');
    this.container.className = 'maplibregl-ctrl maplibregl-ctrl-group maplibregl-ctrl-more';

    const root = createRoot(this.container);
    root.render(
      <TranslationsProvider translator={translator}>
        <MoreToggle map={map} controlRefs={this.controlRefs} />
      </TranslationsProvider>,
    );

    return this.container;
  }

  onRemove() {
    this.container.removeEventListener('click', this.toggleControls);
    this.container.parentNode.removeChild(this.container);
  }
}

export default MoreControl;