import { setSignedCookies } from '@services/cookie.service';
import cookies from './settings/cookies.config';

function reduceToAcceptedCategories(cookieCategorySelection) {
  return cookieCategorySelection.reduce((acc, curr) => {
    if (!curr.accepted) {
      return acc;
    }

    return acc.concat([curr.id]);
  }, []);
}

function getAcceptedCookies(categoryCookies) {
  return cookies
    .map((curr) => {
      if (curr.name !== 'cookie-agreed-categories') {
        return { ...curr };
      }

      return { ...curr, value: reduceToAcceptedCategories(categoryCookies).join() }
    })
    .filter((curr) => {
      const cookieCategory = categoryCookies.filter(
        category => (category.id === curr.category),
      )[0];
      
      return cookieCategory.accepted;
    });
}

/*  EXPORTS
    =============================================================== */
function submitCookies(cookieCategorySelection) {
  const acceptedCategoryNames = reduceToAcceptedCategories(cookieCategorySelection);
  const acceptedCookies = getAcceptedCookies(cookieCategorySelection);
  
  return setSignedCookies(acceptedCookies, acceptedCategoryNames)
}

export {
  submitCookies,
};