import { useSelector, useDispatch } from 'react-redux';
import { useState, useRef, useEffect } from 'react';

import MaplibreMap from '@library/MaplibreMap/MaplibreMap'
import LayerSelector from '@App/components/LayerSelector/LayerSelector';
import LocationSearch from '@App/components/LocationSearch/LocationSearch';
import BackgroundLayerSelector from '@App/components/BackgroundLayerSelector/BackgroundLayerSelector';
import { useAuth } from '@App/components/AuthProvider/auth-provider.context';
import { Button } from '@nazka/nazka.mapframe.components';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { setShowData } from '@templates/T1/t1.redux.actions';
import { usePrev } from '@services/hooks.service';
import gsap from 'gsap';
import CompareModeMap from '../../../components/CompareModeMap/CompareModeMap';

import './interactive-map.styl';

function InteractiveMap() {
  const { isLoggedIn } = useAuth();
  const dispatch = useDispatch()

  const [isMapLoading, setIsMapLoading] = useState(true)
  const [mapResizeId, setMapResizeId] = useState()
  // const showBuildingDamages = useSelector(state => state.layerSelector.showBuildingDamages);
  const showData = useSelector(state => state.t1.showData);
  const isCompareModeOn = useSelector(state => state.buildingDamages.isComparisonModeOn)
  // const { mediaBreakpoint } = useSelector(state => state.appWindow.resizeProps);
  const showBuildingDamages = useSelector(state => state.layerSelector.showBuildingDamages);
  const currentDamage = useSelector(state => state.layerSelector.currentSelectedDamageLayer);
  const datapanelToggleWrapper = useRef()
  const previousCurrentDamage = usePrev(currentDamage)

  useEffect(() => {
    if (!showData && previousCurrentDamage !== currentDamage) {
      setTimeout(() => {
        gsap.to(datapanelToggleWrapper.current, {
          keyframes: {
            '0%': { y: 0 },
            '75%': { y: -10 },
            '100%': { y: 0 },
          },
          ease: 'none', 
          duration: 0.5,
          repeat: 2,
        })
      }, 1500)
    }
  }, [currentDamage, previousCurrentDamage, showData])

  return (
    <div className={`interactive-map ${showData ? 'interactive-map--has-data' : ''}`}>
      
      {isCompareModeOn ? (
        <div className="interactive-map__compare__map">
          <CompareModeMap />
        </div>
      ) : (
        <div className="interactive-map__map">
          <MaplibreMap 
            mapResizeId={mapResizeId}
            onMapInitialized={() => setIsMapLoading(false)}
          />

        </div>
      )}

      {!isCompareModeOn 
        && (
        <div className="interactive-map__backgound__layer__selector__wrapper">
          <BackgroundLayerSelector />
        </div>
        )}
      
      <div className="interactive-map__search__wrapper">
        <LocationSearch />
      </div>
      {showBuildingDamages 
        && (
        <div
          className="interactive-map__datapanel__toggle"
          ref={datapanelToggleWrapper}
        >
          <Button 
            className="datapanel__toggle"
            icon={showData ? faChevronDown : faChevronUp}
            onClick={() => dispatch(setShowData(!showData))}
            themeColor="primary"
            css={{
              padding: '10px 30px',
              borderRadius: '3px',
              boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
            }}
          />
        </div>
        )}
      
      {isLoggedIn && (
        <div className="interactive-map__layer_wrapper">
          <LayerSelector />
        </div>
      )}
    </div>
  )
}

export default InteractiveMap;