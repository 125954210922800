const buildRadioOptions = (floodDamages, userAreas) => {
  const userAreaIds = userAreas.map(area => area.id);

  const result = floodDamages.filter((damage) => {
    return damage.hasOwnProperty('areaId') && userAreaIds.includes(damage.areaId);
  });
  
  const updatedRadio = {};
  result.forEach((damage) => {
    const { areaId } = damage;
    if (!updatedRadio[areaId]) {
      updatedRadio[areaId] = []; // Initialize if not already present
    }
    // console.log(damage)
    updatedRadio[areaId].push({
      label: damage.description,
      value: damage.id,
    });
  });
  return updatedRadio
}

export {
  buildRadioOptions,
}