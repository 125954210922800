/* eslint-disable lines-between-class-members */

import axios from 'axios';
import { translateString } from './translator.utils';

class Translator {
  #config = {};
  #language = undefined;
  #languages = [];
  #loading = false;
  #requestController = undefined;
  #subscribers = {};
  #translations = undefined;

  constructor(config, initialLang) {
    const { languages } = config;

    this.#config = config;
    this.#languages = languages;

    this.#setLanguage(initialLang);
  }

  #getSubscribers() {
    return Object.values(this.#subscribers);
  }

  #notifyLoadingChange(loading) {
    this.#getSubscribers().forEach((subscriber) => {
      if (subscriber.onLoadingChange) {
        subscriber.onLoadingChange(loading);
      }
    });
  }

  #notifyLanguageChange(language) {
    this.#getSubscribers().forEach((subscriber) => {
      if (subscriber.onLanguageChange) {
        subscriber.onLanguageChange(language);
      }
    });
  }

  #notifyTranslationsChange(translations) {
    this.#getSubscribers().forEach((subscriber) => {
      if (subscriber.onTranslationsChange) {
        subscriber.onTranslationsChange(translations);
      }
    });
  }

  #setLoading(loading) {
    this.#loading = loading;
    this.#notifyLoadingChange(loading);
  }

  #setTranslations(translations) {
    this.#translations = translations;
    this.#notifyTranslationsChange(translations);
  }

  async #setLanguage(language) {
    if (!language || !this.#languages.includes(language)) {
      throw new Error('Invalid language passed');
    }

    try {
      this.#setLoading(true);

      // There is an ongoing translations request
      if (this.#requestController) {
        // The current translations request is for the same language
        // Returning early makes it so we don't send unnecessary requests
        if (this.#language === language) {
          return;
        }

        // We are currently fetching translations for a different language
        // The previous request can be aborted since we are switching to a new language
        this.#requestController.abort();
      }

      this.#language = language;

      const controller = new AbortController();
      this.#requestController = controller;

      const { data } = await axios.get(`/locale/${this.#language}.json`, {
        headers: {
          'Cache-Control': 'public',
        },
        signal: controller.signal,
      });

      document.documentElement.setAttribute('lang', language);
      this.#notifyLanguageChange(language);

      this.#setTranslations(data);
    } catch (err) {
      console.error('i18n: ERROR - Something went wrong handling the language change');
      console.error(err);
    } finally {
      this.#requestController = undefined;
      this.#setLoading(false);
    }
  }

  getLanguage() {
    return this.#language;
  }

  getLanguages() {
    return this.#languages;
  }

  setLanguage(language) {
    this.#setLanguage(language);
  }

  subscribe(id, subscriptions = {}) {
    this.#subscribers[id] = subscriptions;
  }

  unsubscribe(id) {
    delete this.#subscribers[id];
  }

  translate(stringId, data = null) {
    return translateString(
      stringId,
      this.#translations,
      data,
      this.#config.pluralization[this.#language],
      this.#config,
      this.#loading,
    );
  }
}

export default Translator;
