import Measurement from './Measurement';
import { formatDistance, getLength, removeFeature } from './measurement.utils';

class MeasurementPolyline extends Measurement {
  constructor(map, control) {
    super(map, control);
    this._shape = 'polyline';
    this._minLength = 2;
    this._hasDistance = true;
    this._hasSurface = false;
    this._finishOnClickLast = this.options.actionsToFinish.includes('click-last');
  }

  commit() {
    if (this.options.displayLength) {
      if (this.coordinates.length > 1 
        && JSON.stringify(this.coordinates[0]) !== JSON.stringify(this.coordinates[1])) {
        const source = this.map.getSource('measurement-control');
        const feature = source._data.features.pop();
        feature.geometry.coordinates = this.coordinates;
        source.setData({ 
          ...source._data, 
          features: [...source._data.features, feature],
        });
        this._updateMeasurePoint(
          feature.geometry.coordinates[feature.geometry.coordinates.length - 1], 
          formatDistance(getLength(feature), this.options.unitSystem),
        );
      } else {
        removeFeature(this.map, this.measurementId);
      }
    }

    this.shape = null;
  }

  _createFeature(id) {
    return {
      type: 'Feature',
      id,
      geometry: {
        type: 'LineString',
        coordinates: this.coordinates,
      },
      properties: {
        shape: 'polyline',
      },
    }
  }

  _addStep(e) {
    let { lngLat } = e;
    lngLat = [lngLat.lng, lngLat.lat];

    if (this._stopMeasurementConditionsMet(lngLat)) {
      this.map.fire('measurement-commit', { lngLat: e.lngLat, control: this.control });
    } else {
      this.coordinates = [...this.coordinates, lngLat];
      const source = this.map.getSource('measurement-control');
      const feature = source._data.features.pop();
      const distance = formatDistance(getLength(feature), this.options.unitSystem);

      feature.geometry.coordinates = this.coordinates;
      feature.properties.distance = distance;
      source.setData({ 
        ...source._data, 
        features: [...source._data.features, feature],
      });

      if (this.options.displayLength && this.coordinates.length > 1) {
        this._updateMeasurePoint([e.lngLat.lng, e.lngLat.lat], distance);
        if (this.coordinates.length > 1) {
          this._addMeasureLine([
            this.coordinates[this.coordinates.length - 1], 
            this.coordinates[this.coordinates.length - 2],
          ]);
        }
      }

      this._addUsabilityDot([e.lngLat.lng, e.lngLat.lat]);
    }
  }

  _render(e) {
    const source = this.map.getSource('measurement-control');
    const feature = source._data.features.pop();
    const coordinates = [...this.coordinates];
    coordinates.push([e.lngLat.lng, e.lngLat.lat]);
    feature.geometry.coordinates = coordinates;

    source.setData({ ...source._data, features: [...source._data.features, feature] });

    if (this.options.displayLength && coordinates.length > 1) {
      this._updateMeasurePoint(
        [e.lngLat.lng, e.lngLat.lat], 
        formatDistance(getLength(feature), this.options.unitSystem),
      );
    }
  }

  _stopMeasurementConditionsMet(lngLat) {
    if (this._finishOnClickLast && this.coordinates.length >= this._minLength) {
      const lastPoint = this.map.project(lngLat);
      const bb = [
        [lastPoint.x - 10, lastPoint.y - 10],
        [lastPoint.x + 10, lastPoint.y + 10],
      ];

      if (this._finishOnClickLast && this.coordinates.length) {
        const prevPoint = this.map.project(this.coordinates[this.coordinates.length - 1]);
        if (bb[0][0] <= prevPoint.x && prevPoint.x <= bb[1][0] 
          && bb[0][1] <= prevPoint.y && prevPoint.y <= bb[1][1]) {
          return true;
        }
      }
    }
    return false;
  }
}

export default MeasurementPolyline;