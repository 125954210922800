import axios from 'axios';
import { toast } from 'react-toastify';

import { refreshTokens } from '@services/auth.service';

function registerAxiosInterceptor(errorCallback) {
  let isRefreshing = false;
  let refreshSubscribers = [];
  
  function executeSubscribers(error) {
    refreshSubscribers.forEach((promise) => {
      if (error) {
        promise.reject(error);
      } else {
        promise.resolve();
      }
    });

    refreshSubscribers = [];
  }
  
  axios.interceptors.response.use(
    // success response => just return response
    response => response,
    // error occured => check if it's an authentication error
    (error) => {
      const { config, response } = error;

      const originalRequest = config;

      if (response.status !== 401) {
        return Promise.reject(error);
      }

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          refreshSubscribers.push({ resolve, reject })
        }).then(() => axios(originalRequest))
          .catch(err => Promise.reject(err));
      }

      isRefreshing = true;
      return new Promise((resolve, reject) => {
        refreshTokens()
          .then(() => {
            executeSubscribers();
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            executeSubscribers(err);
            reject(err);
            toast.error('Session expired, please login again');
            errorCallback();
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    },
  );
}

export {
  registerAxiosInterceptor,
};