import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import getArea from '@turf/area';

import GeneralInformation from '@App/components/BuildingDamages/GeneralInformation/GeneralInformation';
import CostGraph from '@App/components/BuildingDamages/CostGraph/CostGraph';
import CostTable from '@App/components/BuildingDamages/CostTable/CostTable';

import { useUserAreas } from '@utils/queries/user.queries';
import { calculateCostPerClass, calculateTotalDamage } from './building-damages.utils';

import './building-damages.styl';
import CompareModeView from './CompareModeView/CompareModeView';

function BuildingDamages() {
  const isCompareModeOn = useSelector(state => state.buildingDamages.isComparisonModeOn)
  const currentSelectedDamageLayer = useSelector(state => state.layerSelector.currentSelectedDamageLayer)
  const { features, totalBuildingCount, areaId } = currentSelectedDamageLayer;

  const totalDamage = calculateTotalDamage(currentSelectedDamageLayer?.features);
  const costPerClass = useMemo(() => calculateCostPerClass(features), [features]);
  const { data: userAreas } = useUserAreas();
  const selectedArea = userAreas?.find(area => area.id === areaId)
  
  return (
    <div className="building-damages">
      <GeneralInformation
        area={selectedArea ? getArea(selectedArea.feature) : ''}
        totalDamage={totalDamage}
        totalBuildingCount={totalBuildingCount}
        totalBuildingsFloodedCount={features.length}
      />
      {isCompareModeOn ? (<CompareModeView />) : (
        <div>
          {features.length > 0 
            ? (
              <div className="building-damages__table-graph__wrapper">
                <CostGraph data={costPerClass} totalDamage={totalDamage} />
                <CostTable data={costPerClass} />
              </div>
            )
            : (
              <span className="building-damages__no__flood__text">No flooded buildings detected</span>
            )}
        </div>
      )}
      
    </div>
  );
}

export default BuildingDamages;