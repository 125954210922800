if (!window.GA_MEASUREMENT_ID) {
  console.log('Google Analytics isn\'t setup for production yet.');
}

function initGoogleAnalytics(advancedAnalyticsBoolean) {
  if (window.GA_MEASUREMENT_ID) {
    window[`ga-disable-${window.GA_MEASUREMENT_ID}`] = false;

    window.dataLayer = window.dataLayer || [];
    window.gtag = (...args) => window.dataLayer.push(args);
    
    gtag('js', new Date());
    gtag('config', window.GA_MEASUREMENT_ID, { 
      anonymize_ip: !advancedAnalyticsBoolean,
      debug_mode: import.meta.env.VITE_NAZKA_ENV !== 'production',
    });
  }
}

export { initGoogleAnalytics };