/* eslint-disable max-len */
import { useEffect } from 'react'

const OrthophotoLayer = ({ map }) => {
  useEffect(() => {
    const source = {
      id: 'Orthophoto',
      type: 'raster',
      tiles: [`/api/tiles?url=${encodeURIComponent('https://storitve.eprostor.gov.si/ows-elf-wms/oi/wms?service=wms&request=GetMap&version=1.3.0&srs=EPSG:3857&transparent=true&layers=OI.OrthoimageCoverage&format=image/png&WIDTH=256&HEIGHT=256')}&bbox={bbox-epsg-3857}`],
      tileSize: 512,
    };
    
    map.addLayer({
      id: 'Orthophoto',
      type: 'raster',
      source,
      paint: {},
    }, 'z-index-0');

    return () => {
      if (map && !map._removed) {
        if (map.getLayer('Orthophoto')) {
          map.removeLayer('Orthophoto');
        }
        
        if (map.getSource('Orthophoto')) {
          map.removeSource('Orthophoto');
        }
      }
    };
  }, [map])
}

export default OrthophotoLayer