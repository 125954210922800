import { useState, useEffect, useCallback } from 'react';
import { get as getCookie, set as setCookie } from 'js-cookie';
import { initGoogleAnalytics } from '@services/analytics.service';

import CookieForm from './CookieForm/CookieForm';
import { submitCookies } from './cookie-bar.utils';

import './cookie-bar.styl';

function CookieBar() {
  const [agreedCookieCategories, setAgreedCookieCategories] = useState(getCookie('cookie-agreed-categories'));

  const onFormSubmit = useCallback((cookieCategorySelection) => {
    submitCookies(cookieCategorySelection).then((response) => {
      if (!getCookie('cookie-agreed-categories') && !!response.data) {
        Object.keys(response.data).forEach((key) => {
          const cookieData = !!response.data[key] && JSON.parse(response.data[key]);
          const { name, value } = cookieData || {};

          if (name) {
            setCookie(name, value);
          }
        })
      }

      setAgreedCookieCategories(getCookie('cookie-agreed-categories'));
    });
  }, []); 

  useEffect(() => {
    if (agreedCookieCategories) {
      // Setup cookie dependent features/services here
      initGoogleAnalytics(agreedCookieCategories.includes('advanced-analytics'));
    }
  }, [agreedCookieCategories]);

  if (agreedCookieCategories) {
    return null;
  }

  return (
    <div className="cookie-bar">
      <div className="cookie-bar__window">
        <div className="cookie-bar__window__header">
          <h1>This site uses cookies to ensure you get the best experience.</h1>
        </div>
        <div className="cookie-bar__window__body">
          <div className="cookie-bar__window__body__info">
            <h2 className="cookie-bar__window__body__info__title">
              Functionality and performance cookies
            </h2>
            <p>
              These cookies are necessary for the website to work properly.
              Some of them are needed to anonymously map your usage of this website 
              for analysis and research purposes.
            </p>
            <p className="cookie-bar__window__body__info__read-more">
              {'Read about the '}
              <a
                className="cookie-bar__window__body__info__read-more__url" 
                href="https://legal.nazka.be/docs/en/cookie-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                cookie policy of nazka mapps and how to manage cookies
              </a>
              .
            </p>
          </div>
          <CookieForm onSubmit={cookieCategorySelection => onFormSubmit(cookieCategorySelection)} />
        </div>
      </div>
    </div>
  )
}

export default CookieBar;