function calculateTotalDamage(features) {
  if (!features) {
    return 0;
  }

  return features.reduce((acc, curr) => acc + curr.properties.totalDamage, 0);
}

function calculateCostPerClass(features) {
  const costPerClass = {
    buildingDamage: {
      value: 0,
      name: 'Building damage',
      color: '#022741',
    },
    equipmentDamage: {
      value: 0,
      name: 'Equipment damage',
      color: '#078BCF',
    },
    vehicleDamage: {
      value: 0,
      name: 'Vehicle damage',
      color: '#099EE1',
    },
    cleaningCosts: {
      value: 0,
      name: 'Cleaning costs',
      color: '#0DC2FF',
    },
    peopleFatality: {
      value: 0,
      name: 'People fatality',
      color: '#0BB0F3',
    },
    peopleInjury: {
      value: 0,
      name: 'People injury',
      color: '#0367A5',
    },
  };

  for (let i = 0; i < features.length; i += 1) {
    const { properties } = features[i];

    Object.keys(costPerClass).forEach((key) => {
      if (properties[key]) {
        costPerClass[key].value += properties[key];
      }
    });
  }

  return Object.values(costPerClass).sort((a, b) => b.value - a.value);
}

export {
  calculateTotalDamage,
  calculateCostPerClass,
};