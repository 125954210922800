import { useSelector } from 'react-redux'

import './compare-mode-layer-panel.styl';

function CompareModeLayerPanelFloodSection() {
  const firstScenario = useSelector(state => state.layerSelector.currentSelectedDamageLayer).scenario.split('_')
  const secondScenario = useSelector(state => state.buildingDamages.secondScenario)?.scenario.split('_')

  return (
    <div className="compare-mode-layer-panel">
      <div className="compare-mode-layer-panel__single-scenario-wrapper">
        <div className="compare-mode-layer-panel__first-scenario-color" />
        <span className="compare-mode-layer-panel__span">
          {firstScenario[0]}
          -year flood,
          {' '}
          {firstScenario[1] ? (
            <span>
              {firstScenario[1] === 'Measures' ? 'anti-flood measures included' : 'climate change included'}
            </span>
          ) : (
            <span>no scenario</span>
          )}
        </span>
      </div>
      {!!secondScenario && (
        <div className="compare-mode-layer-panel__single-scenario-wrapper">
          <div className="compare-mode-layer-panel__second-scenario-color" />
          <span className="radio__span">
            {secondScenario[0]}
            -year flood,
            {' '}
            {secondScenario[1] ? (
              <span>
                {secondScenario[1] === 'Measures' ? 'anti-flood measures' : 'climate change included'}
              </span>
            ) : (
              <span>no scenario</span>
            )}
          </span>
        </div>
      )}
    </div>
  )
}

export default CompareModeLayerPanelFloodSection