import maplibregl from 'maplibre-gl'
import { useEffect, useState } from 'react';
import { Icon, Button, Portal } from '@nazka/nazka.mapframe.components';
import { faHouseFloodWater, faXmark } from '@fortawesome/pro-solid-svg-icons';
import Select from 'react-select'

import FloodsLayer from '@App/components/FloodsLayer/FloodsLayer';
import { useUpdateUserSettingsMutation } from '@utils/queries/user.queries';

import { mainOptions, secondaryOptions } from './flood-scenario-modal.constants';

import './flood-scenario-modal.styl';
import { createLayers } from '../CompareModeMap/compare-mode-map.utils';

function FloodScenarioModal({ closeModal, floodYear = '10', floodMeasures = null }) {
  const [map, setMap] = useState(null);

  const [selectedMain, setSelectedMain] = useState(mainOptions.find(o => o.value === floodYear) || mainOptions[0]);
  const [selectedSecondary, setSelectedSecondary] = useState(secondaryOptions.find(o => o.value === floodMeasures) || secondaryOptions[0]);

  useEffect(() => {
    const locationMap = new maplibregl.Map({
      container: 'location-map',
      attributionControl: false,
      style: `https://api.maptiler.com/maps/basic-v2-light/style.json?key=${import.meta.env.VITE_MAPTILER_TOKEN}`,
      center: [14.5057515, 46.0569465],
      zoom: 9,
      interactive: false,
    });

    locationMap.on('load', () => {
      setMap(locationMap);
      createLayers(locationMap)
    });

    return () => {
      if (locationMap) {
        setMap(null)
        locationMap.remove();
      }
    };
  }, []);

  const handleMainSelectChange = (selectedOption) => {
    setSelectedMain(selectedOption);
  };

  const handleSecondarySelectChange = (selectedOption) => {
    setSelectedSecondary(selectedOption);
  }

  const { mutate: updateUserSettings } = useUpdateUserSettingsMutation();

  const handleConfirmation = () => {
    updateUserSettings({ floodYear: selectedMain?.value, floodMeasures: selectedSecondary?.value });
    closeModal();
  }

  const scenario = `${selectedMain?.value || '10'}${selectedSecondary?.value || ''}`;

  return (
    <Portal container={document.getElementById('js-mapframe-portal')}>
      <div className="flood-scenario-modal__container">
        <div className="flood-scenario-modal__wrapper">
          <div className="flood-scenario-modal__wrapper__header">
            <div className="flood-scenario-modal__wrapper__header__icon__title">
              <div className="flood-scenario-modal__icon__wrapper">
                <Icon
                  icon={faHouseFloodWater}
                  size="25px"
                />
              </div>
              <span>Flood Scenario</span>
            </div>
            <div>
              <Button
                type="button"
                icon={faXmark}
                color="white"
                onClick={closeModal}
              />
            </div>
          </div>
          <div className="flood-scenario__dropdown__wrapper">
            <Select
              options={mainOptions}
              className="flood-scenario__dropdown"
              value={selectedMain}
              onChange={handleMainSelectChange}
            />
            <Select
              options={secondaryOptions}
              className="flood-scenario__dropdown"
              value={selectedSecondary}
              onChange={handleSecondarySelectChange}
              placeholder="No scenario"
            />
          </div>
          <div className="flood-scenario__map__wrapper">
            <div className="flood-scenario-modal__map" id="location-map" />
          </div>
          <div className="flood-scenario_button_wrapper">
            <Button
              color="#0367A5"
              css={{
                borderRadius: '3px',
              }}
              onClick={handleConfirmation}
            >
              Confirm
            </Button>
          </div>
        </div>
        {map && <FloodsLayer map={map} scenario={scenario} />}
      </div>
    </Portal>
  );
}

export default FloodScenarioModal