import { Toggle } from '@nazka/nazka.mapframe.components';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import './background-layer-selector.styl';
import OrthophotoLayer from '../OrthophotoLayer/OrthophotoLayer';
import Municipality from '../Municipality/Municipality';

function BackgroundLayerSelector() {
  const [isOpen, setIsOpen] = useState(false);
  const [showOrthophoto, setShowOrthophoto] = useState(false);
  const [showMunicipality, setShowMunicipality] = useState(false);
  const map = useSelector(state => state.maplibreMap.map);

  const toggleIsOpen = () => {
    setIsOpen(prevState => !prevState);
  };

  const toggleShowOrthophoto = (e) => {
    setShowOrthophoto(e.target.checked);
  };

  const toggleShowMunicipality = (e) => {
    setShowMunicipality(e.target.checked);
  };

  return (
    <div className="backgound-layer-selector__container">
      <div className={`z-depth-1 backgound-layer-selector__options__wrapper
       ${isOpen ? 'backgound-layer-selector__options__wrapper--expanded' : 'backgound-layer-selector__options__wrapper--closed'}`}
      >
        <div>
          <div>
            <Toggle
              label="Orthophoto"
              name="Orthophoto"
              checked={showOrthophoto}
              onInputChange={toggleShowOrthophoto}
              activeColor="#0367A5"
              css={{ 
                fontSize: '14px',
                input: { fontSize: '12px' },
                color: '#0367A5',
              }}
            />
          </div>
        </div>
        <div>
          <div>
            <Toggle
              label="Municipalities"
              name="Municipalities"
              checked={showMunicipality}
              onInputChange={toggleShowMunicipality}
              activeColor="#0367A5"
              css={{ 
                fontSize: '14px',
                input: { fontSize: '12px' },
                color: '#0367A5',
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <button type="button" onClick={toggleIsOpen} className="background-layer-selector__button" />
      </div>

      {map && showOrthophoto && (
        <OrthophotoLayer map={map} />
      )}
      {map && showMunicipality && (
      <Municipality map={map} />
      )}
    </div>
  )
}

export default BackgroundLayerSelector