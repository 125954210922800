import axios from 'axios';

async function signIn(payload) {
  const { data } = await axios.post('/api/auth/sign-in', payload);
  return data;
}

async function firstSignIn(payload) {
  const { data } = await axios.post('/api/auth/first-sign-in', payload);
  return data;
}

async function signOut() {
  await axios.get('/api/auth/sign-out');
}

async function testToken() {
  await axios.get('/api/auth/test-token');
}

async function refreshTokens() {
  await axios.get('/api/auth/refresh-token');
}

export {
  signIn,
  firstSignIn,
  signOut,
  testToken,
  refreshTokens,
};