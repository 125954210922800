const createLayers = (map) => {
  for (let i = 0; i < 10; i += 1) {
    map.addLayer({
      id: `z-index-${i}`,
      type: 'fill',
      source: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      },
      paint: {},
    });
  }
}

export {
  createLayers,
}