import length from '@turf/length';
import centroid from '@turf/centroid';
import area from '@turf/area';
import store from '@App/app.store'

import { setShowActiveDrawingLayer, setPopup } from '@library/MaplibreMap/maplibre-map.redux.actions';
import { 
  UNITS, metricLengthUnits, metricAreaUnits, imperialLengthUnits, imperialAreaUnits, 
} from './measurement.constants';

function getLength(feature, unit) {
  const lengthInMeters = length(feature) * 1000;
  if (unit && UNITS[unit]) {
    return lengthInMeters * UNITS[unit].factor;
  }
  return lengthInMeters;
}

function getArea(feature, unit) {
  const areaInSquareMeters = area(feature);
  if (unit && UNITS[unit]) {
    return areaInSquareMeters * UNITS[unit].factor;
  }
  return areaInSquareMeters;
}

function getPolygonCenterPoint(feature) {
  return centroid(feature);
}

function numberWithCommas(x) {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

function getUnit(units, number) {
  const unitsSmallEnough = Object.values(units).filter(unit => number * unit.factor >= 1);
  if (unitsSmallEnough.length === 1) {
    return unitsSmallEnough[0];
  }
  if (unitsSmallEnough.length > 1) {
    const unitToReturn = unitsSmallEnough
      .reduce((acc, curr) => (curr.factor < acc.factor ? curr : acc), { factor: 1 });

    if (unitToReturn) return unitToReturn;
  }

  const unitToReturn = Object.values(units)
    .reduce((acc, curr) => (curr.factor > acc.factor ? curr : acc), { factor: 0 });
  return unitToReturn;
}

function formatDistance(disNumber, unitSystem) {
  const unitsAvailable = unitSystem === 'imperial' ? imperialLengthUnits : metricLengthUnits;
  const unit = getUnit(unitsAvailable, disNumber);
  const numberToDisplay = disNumber * unit.factor;

  return `${numberWithCommas(numberToDisplay.toFixed(unit.decimals))} ${unit.display}`;
}

function formatArea(areaNumber, unitSystem) {
  const unitsAvailable = unitSystem === 'imperial' ? imperialAreaUnits : metricAreaUnits;
  const unit = getUnit(unitsAvailable, areaNumber);
  const numberToDisplay = areaNumber * unit.factor;

  return `${numberWithCommas(numberToDisplay.toFixed(unit.decimals))} ${unit.display}`;
}

function removeFeature(map, id) {
  store.dispatch(setPopup(null))
  const source = map.getSource('measurement-control');
  const options = ['circle', 'rectangle', 'polygon']
  options.forEach((shape) => {
    if (map.getLayer(`measurement-control-${shape}`)) {
      map.setPaintProperty(`measurement-control-${shape}`, 'fill-color', 'rgba(3, 103, 165, 0.046)');
    }
  });
  
  const features = source._data.features.filter(f => !(f.id === id || f.properties.measurementId === `${id}_radius`));
  source.setData({ ...source._data, features });

  const labelSource = map.getSource('measurement-control-labels');
  const labelFeatures = labelSource._data.features.filter((f) => {
    return f.properties.measurementId !== id;
  });
  labelSource.setData({ ...labelSource._data, features: labelFeatures });

  const dotSource = map.getSource('measurement-control-usability-dots');
  const dotFeatures = dotSource._data.features.filter((f) => {
    return f.properties.measurementId !== id;
  });
  dotSource.setData({ ...dotSource._data, features: dotFeatures });
}

function removeAllFeatures(map) {
  store.dispatch(setPopup(null))
  store.dispatch(setShowActiveDrawingLayer(false))
  const options = ['circle', 'rectangle', 'polygon']
  options.forEach((shape) => {
    if (map.getLayer(`measurement-control-${shape}`)) {
      map.setPaintProperty(`measurement-control-${shape}`, 'fill-color', 'rgba(3, 103, 165, 0.046)');
    }
  });

  const source = map.getSource('measurement-control');
  source.setData({ ...source._data, features: [] });

  const labelSource = map.getSource('measurement-control-labels');
  labelSource.setData({ ...labelSource._data, features: [] });

  const dotSource = map.getSource('measurement-control-usability-dots');
  dotSource.setData({ ...dotSource._data, features: [] });
}

export { 
  formatDistance,
  formatArea,
  getPolygonCenterPoint,
  removeFeature,
  removeAllFeatures,
  getLength,
  getArea,
}